<template>
  <div class="anipang3 wrapper">
    <div id="intro"
         class="intro section">
      <div class="container">
        <br><br><br><br>
        <h2 class="section-title">
          <img :src="sections.intro.title" alt="귀여운 콜렉션으로 완성하는 수집형 3매치, 애니팡3"/>
        </h2>
        <div class="md-layout">
          <div class="md-layout-item md-size-50 md-small-size-100 text-center">
            <img :src="sections.intro.phone" alt="phone">
          </div>
          <div class="section-contents md-layout-item md-size-50 text-center md-small-size-100 mx-auto">
            <div class="section-description">
              <h3 class="game-description">
                퍼즐도 미션도 캐릭터와 함께!<br>
                강력해진 특수블록으로 화끈하게!<br>
                <br>
                귀여운데 강력한 캐릭터들과
                애니팡3를 바로 만나보세요<br>
              </h3>
              <br><br><br>
              <div class="game-tag">
                <span>#동화로부터_막도착</span><br>
                <span>#막힐땐_캐릭터파워</span><br>
                <span>#펫_아리도_있어요</span>
              </div>
            </div>
          </div>
        </div>
        <br>
        <br>
        <div class="down-links md-layout">
          <a href="https://anipang3.onelink.me/BAqa?af_ios_url=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.sundaytoz.kakao.anipang3.service&af_web_dp=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.sundaytoz.kakao.anipang3.service&af_xp=custom&pid=Brandpage&c=2212_AP3_KR_APS_BR_PMO_BRANDPAGE_SUB" target="_blank" class="md-layout-item md-size-50 md-small-size-100">
            <img :src="sections.intro.google_bt" alt="google_btn">
          </a>
          <a href="https://anipang3.onelink.me/BAqa?af_android_url=https%3A%2F%2Fapps.apple.com%2Fkr%2Fapp%2Fid1126201781&af_web_dp=https%3A%2F%2Fapps.apple.com%2Fkr%2Fapp%2Fid1126201781&af_xp=custom&pid=Brandpage&c=2212_AP3_KR_APS_BR_PMO_BRANDPAGE_SUB" target="_blank" class="md-layout-item md-size-50 md-small-size-100">
            <img :src="sections.intro.apple_bt" alt="app_btn">
          </a>
<!--          <a href="https://anipang3.onelink.me/BAqa?af_android_url=https%3A%2F%2Fapps.apple.com%2Fkr%2Fapp%2Fid1126201781&af_xp=custom&pid=Brandpage&c=2212_AP3_KR_APS_BR_PMO_BRANDPAGE_SUB" target="_blank" class="md-layout-item md-size-50 md-small-size-100">-->
<!--            <img :src="sections.intro.apple_bt" alt="app_btn">-->
<!--          </a>-->
        </div>
        <br><br><br>
      </div>
    </div>

  </div>
</template>

<script>

  export default {
    bodyClass: "anipang3",
    created() {},
    computed: {},
    data() {
      return {
        sections: {
          intro: {
            title: require("@/assets/img/pages/sub_page_a3_main_title.png"),
            phone: require("@/assets/img/pages/sub_page_a3_phone.png"),
            apple_bt: require("@/assets/img/pages/apple_bt.png"),
            google_bt: require("@/assets/img/pages/google_bt.png"),
          },
        }
      };
    }
  };
</script>

<style lang="scss">

</style>
<style lang="scss" scoped>
  .anipang3 {
    .intro {
      background: rgb(255,236,237);
      background: linear-gradient(135deg, rgba(255,153,150,1) 0%, rgba(255,236,237,1) 100%);

      .container {
        .section-title {
          text-align: center;
        }
        .section-contents {
          padding-left: 30px !important;
          padding-right: 30px !important;
          display: flex;
          flex-wrap: wrap;
          align-content: center;

          .section-description {
            -webkit-font-smoothing: antialiased;
            text-align: left;

            .game-title{
              color: #732f35;
            }
            .game-description{
              color: #732f35;
            }
            .game-tag {
              span {
                color: #732f35;
                border-radius: 100px;
                background-color: white;
                border: 1px solid #732f35;
                padding: 10px 15px;
                margin: 5px 5px 5px 0;
                display: inline-block;
                font-size: 23px;
                box-shadow: 0 2px 5px rgba(33, 33, 33, 0.5);
              }
            }
          }
        }

        .down-links {
          margin: 0 auto;
          max-width: 600px;

          .md-layout-item {
            text-align: center;
          }
        }
      }
    }
  }
</style>